import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// Main
import link_terkait from './../views/link_terkait/link_terkait.vue'

import berita from './../views/berita/berita.vue'

// AGENDA PENGUMUMAN 
import agenda from './../views/agenda_pengumuman/agenda.vue'
import pengumuman from './../views/agenda_pengumuman/pengumuman.vue'

import dokumen_OPD from './../views/dokumen_OPD/dokumen_OPD.vue'

// dinaskeswan
import disnakkeswan_slider from'./../views/disnakkeswan/profil/disnakkeswan_slider.vue'
import disnakkeswan_sambutan from'./../views/disnakkeswan/profil/disnakkeswan_sambutan.vue'
import disnakkeswan_tentang from'./../views/disnakkeswan/profil/disnakkeswan_tentang.vue'
import disnakkeswan_visi_misi from'./../views/disnakkeswan/profil/disnakkeswan_visi_misi.vue'
import disnakkeswan_tupoksi from'./../views/disnakkeswan/profil/disnakkeswan_tupoksi.vue'
import disnakkeswan_struktur_organisasi from'./../views/disnakkeswan/profil/disnakkeswan_struktur_organisasi.vue'
import disnakkeswan_profil_pejabat from'./../views/disnakkeswan/profil/disnakkeswan_profil_pejabat.vue'
import disnakkeswan_regulasi from'./../views/disnakkeswan/disnakkeswan_regulasi.vue'
import disnakkeswan_bidang from'./../views/disnakkeswan/disnakkeswan_bidang.vue'
import disnakkeswan_pengumuman from'./../views/disnakkeswan/disnakkeswan_pengumuman.vue'
import disnakkeswan_layanan from'./../views/disnakkeswan/disnakkeswan_layanan.vue'
import disnakkeswan_data from'./../views/disnakkeswan/data_dokumen/disnakkeswan_data.vue'
import disnakkeswan_dokumen from'./../views/disnakkeswan/data_dokumen/disnakkeswan_dokumen.vue'
import disnakkeswan_foto from'./../views/disnakkeswan/galeri/disnakkeswan_foto.vue'
import disnakkeswan_video from'./../views/disnakkeswan/galeri/disnakkeswan_video.vue'

// 

Vue.use(VueRouter);


function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}


const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },

  // main
  {
    path: '/berita',
    name: 'berita',
    component: berita,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: agenda,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/pengumuman',
    name: 'pengumuman',
    component: pengumuman,
    beforeEnter: isLoggedIn,
  },
  
  
  {
    path: '/link_terkait',
    name: 'link_terkait',
    component: link_terkait,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/dokumen_OPD',
    name: 'dokumen_OPD',
    component: dokumen_OPD,
    beforeEnter: isLoggedIn,
  },

  // keswan
  {
    path: '/disnakkeswan_slider',
    name: 'disnakkeswan_slider',
    component: disnakkeswan_slider,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_sambutan',
    name: 'disnakkeswan_sambutan',
    component: disnakkeswan_sambutan,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_tentang',
    name: 'disnakkeswan_tentang',
    component: disnakkeswan_tentang,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_visi_misi',
    name: 'disnakkeswan_visi_misi',
    component: disnakkeswan_visi_misi,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_tupoksi',
    name: 'disnakkeswan_tupoksi',
    component: disnakkeswan_tupoksi,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_struktur_organisasi',
    name: 'disnakkeswan_struktur_organisasi',
    component: disnakkeswan_struktur_organisasi,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_profil_pejabat',
    name: 'disnakkeswan_profil_pejabat',
    component: disnakkeswan_profil_pejabat,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_pengumuman',
    name: 'disnakkeswan_pengumuman',
    component: disnakkeswan_pengumuman,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_layanan',
    name: 'disnakkeswan_layanan',
    component: disnakkeswan_layanan,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_bidang',
    name: 'disnakkeswan_bidang',
    component: disnakkeswan_bidang,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_data',
    name: 'disnakkeswan_data',
    component: disnakkeswan_data,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_dokumen',
    name: 'disnakkeswan_dokumen',
    component: disnakkeswan_dokumen,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_regulasi',
    name: 'disnakkeswan_regulasi',
    component: disnakkeswan_regulasi,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_foto',
    name: 'disnakkeswan_foto',
    component: disnakkeswan_foto,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/disnakkeswan_video',
    name: 'disnakkeswan_video',
    component: disnakkeswan_video,
    beforeEnter: isLoggedIn,
  },


]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
