

const ADD_URL = URL_APP + "api/v1/server_disnakkeswan_bidang/";
const FILE_LOAD = URL_APP + "uploads/";


export default {

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                unit:"",
                judul : '',
                uraian : '',
                file : '',
                unit_kerja : '',
            },
            id :'',
            cek_load_data : true,
            file_old: "",
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",

            mdl_add: false,
            mdl_edit: false,
            mdl_hapus : false,
            mdl_lihat : false,
            mdl_lihat_gambar : false,
        }
    },
    methods: {
    getView: function() {
        this.cek_load_data = true;
      fetch(ADD_URL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
           this.cek_load_data = false;
        });
    },

    addData: function() {
        var formData = new FormData();

        formData.append("unit", this.form.unit);
        formData.append("judul", this.form.judul);
        formData.append("uraian", this.form.uraian);
        formData.append("unit_kerja", this.form.unit_kerja);
        formData.append("file", this.form.file);

        fetch(ADD_URL + "addData", {
            method: "POST",
            headers: {
            authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
    },

    editData: function() {
        var formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("unit", this.form.unit);
        formData.append("judul", this.form.judul);
        formData.append("uraian", this.form.uraian);
        formData.append("unit_kerja", this.form.unit_kerja);
        formData.append("file", this.form.file);
        formData.append("file_old", this.file_old);

        fetch(ADD_URL + "editData", {
            method: "POST",
            headers: {
            authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
    },

    selectData: function(data) {
        this.form = {
            id : data.id,
            unit : data.unit,
            judul : data.judul,
            uraian : data.uraian,
            file : data.file,
            unit_kerja : data.unit_kerja,
        };
        this.file_old =  data.file;
    },


    removeData: function(idnya, file) {
      fetch(ADD_URL + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          id: idnya,
          file: file
        })
      }).then(res_data => {
        this.getView();
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        this.fileku = null;
      });
    },

    mdl_exit: function() {
      this.judul = "";
      this.uraian = "";
      this.file = "";
      this.file_old = "";
    },

  // ================== PAGINASI ====================
  Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },



    btn_prev : function(){
            this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
    onFileSelected: function(event) {
      this.form.file = event.target.files[0];
    },
    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    }
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {
    var get_profile = JSON.parse(localStorage.profile);
    // this.unit_kerja_full = get_profile.profile.unit_kerja_nama;
    this.form.unit_kerja = get_profile.profile.unit_kerja;

    this.getView();

    

        
    }
};
