

import Chart from 'chart.js';

const populasi_dpkh = "https://server.populasi-dpkh.konaweselatankab.go.id/";
const URL_JENIS = populasi_dpkh + "api/v1/statistik_peternakan_publish_home/";
const URL_KECAMATAN = populasi_dpkh + "api/v1/statistik_peternakan_publish_kecamatan/";
const FILE_LOAD = populasi_dpkh + "uploads/"; 

export default {
  data() {
    return {
      list_data : [],
      file_path: FILE_LOAD,
      img: 'img:',

      master_thn_id : '',
      kecamatan_id : '',
      master_jenis_ternak_id : '',

      list_jenis :[],
      list_kelas :[],
      list_kecamatan :[],
      list_jenis_ternak :[],

      // list_tahun: [
      //       // { id: '', tahun: 'Pilih Tahun'},
      //       { id: 2017, tahun: 2017},
      //       { id: 2018, tahun: 2018},
      //       { id: 2019, tahun: 2019},
      //       { id: 2020, tahun: 2020},
      //       { id: 2021, tahun: 2021},
      //       { id: 2022, tahun: 2022},
      //       { id: 2023, tahun: 2023},
      //       { id: 2024, tahun: 2024},
      //       { id: 2025, tahun: 2025},
      //       { id: 2026, tahun: 2026},
      //       { id: 2027, tahun: 2027},
      // ],

      
    }
  },
  methods: {

    // cari_data : function(){

    //   this.getJenisTernak();
    // },

    load_data : function(){
            this.getJenis();
            this.jenis_ternak_tahun();
            this.dinamika_eksport();
            this.dinamika_import();
        },
    
        getJenis: function() {

            console.log(this.kecamatan_id, this.master_thn_id);
            fetch(URL_JENIS + "jenis_ternak", {
                method: "POST",
                headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                  kecamatan_id : this.kecamatan_id,
                  master_thn_id : this.master_thn_id,
                    
                })
            })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data;
               
            });
        },


        getKec: function() {
            // this.cek_load_data = true;
            fetch(URL_KECAMATAN , {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    // console.log(res_data);
                    this.list_kecamatan = res_data;
                        
            });
        },

        getJenisTernak: function() {
            fetch(URL_JENIS + "getJenisTernak", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({})
            })
                .then(res => res.json())
                .then(res_data => {
                    // console.log(res_data);
                    this.list_jenis_ternak = res_data;
                    this.master_jenis_ternak_id = res_data[0].master_jenis_ternak_id
                    // console.log(this.master_jenis_ternak_id);
                    this.dinamika_eksport();
                    this.dinamika_import();

            });
        },


        jenis_ternak_tahun : function(){

            fetch(URL_JENIS + "jenis_ternak_tahun", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {

                    var labelsku = [];
                    var dataku1 = []; var dataku2 = []; var dataku3 = []; var dataku4 = []; var dataku5 = [];

                    for (let i = 0; i < res_data.length; i++) {
                        labelsku.push(res_data[i].uraian);
                        dataku1.push(res_data[i].tahun1);
                        dataku2.push(res_data[i].tahun2);
                        dataku3.push(res_data[i].tahun3);
                        dataku4.push(res_data[i].tahun4);
                        dataku5.push(res_data[i].tahun5);
                        
                    }


                    new Chart(document.getElementById("jenis_ternak_tahun"), {
                    type: 'bar',
                    data: {
                        labels: labelsku,
                        datasets: [
                            {
                                label: "2016",
                                backgroundColor: "#FFDF5E",
                                data: dataku1
                            }, 
                            {
                                label: "2017",
                                backgroundColor: "#EBC7BC",
                                data: dataku2
                            },
                            {
                                label: "2018",
                                backgroundColor: "#D75EFF",
                                data: dataku3
                            },
                            {
                                label: "2019",
                                backgroundColor: "#B2CDEB",
                                data: dataku4
                            },
                            {
                                label: "2020",
                                backgroundColor: "#80FFA5",
                                data: dataku5
                            },
                        ]
                        },
                        options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: true,
                            text: 'Jumlah Populasi Ternak'
                        }
                    }
                });

            });


            
        },

        dinamika_eksport : function(){
            var narasi = 'Jumlah Eksport '
            var id_canvas = 'dinamika_eksport';
            var judul = "Dinamika Eksport "
            
            fetch(URL_JENIS + "dinamika_eksport", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                    master_jenis_ternak_id : this.master_jenis_ternak_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    this.lineChart(res_data, narasi, id_canvas, judul);

                    

            });



            
        },
        dinamika_import : function(){
            var narasi = 'Jumlah Import '
            var id_canvas = 'dinamika_import';
            var judul = "Dinamika Import "
            
            fetch(URL_JENIS + "dinamika_import", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                    master_jenis_ternak_id : this.master_jenis_ternak_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    this.lineChart(res_data, narasi, id_canvas, judul);

                    

            });



            
        },

        lineChart : function(res_data, narasi, id_canvas, judul){
          new Chart(document.getElementById(id_canvas), {
                type: 'line',
                data: {
                    labels: [2016,2017,2018,2019,2020],
                    datasets: [{ 
                        data: [res_data.tahun1,res_data.tahun2,res_data.tahun3,res_data.tahun4,res_data.tahun5],
                        label: narasi + res_data.uraian,
                        borderColor: "#3e95cd",
                        fill: false
                    }
                    ]
                },
                options: {
                    legend: {display: false},
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                    display: true,
                    text: judul+res_data.uraian
                    }
                }
            });
        },

  },

  mounted () {
    this.getJenisTernak();
    var d = new Date();
    this.master_thn_id = d.getFullYear()-1;
    this.getKec();
    this.load_data();
    

  },
}
