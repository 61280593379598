import { render, staticRenderFns } from "./disnakkeswan_sambutan.vue?vue&type=template&id=0cb756ce"
import script from "./disnakkeswan_sambutan.vue?vue&type=script&lang=js"
export * from "./disnakkeswan_sambutan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QImg,QBtnGroup,QDialog,QEditor,QFile,QIcon,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QImg,QBtnGroup,QDialog,QEditor,QFile,QIcon,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
