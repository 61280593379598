
const URL_APP = "http://localhost:5014/";
const ADD_URL = URL_APP + "api/v1/web_pengumuman/";
const FILE_LOAD = URL_APP + "uploads/";


export default {
    components: {

  },

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                judul : '',
                isi : '',
                file : '',
                file_type : '',
                unit_kerja : '',
                status : '',
                keterangan : '',
                createBy : '',
                createAt : '',
            },
            id :'',
            cek_load_data : true,
            file_old: "",
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",

            content: '<p>example content</p>',
        editorOption: { /* quill options */ }
        }
    },
    methods: {
        onEditorChange(event) {
        console.log('onEditorChange')
      },
    getView: function() {
        this.cek_load_data = true;
      fetch(ADD_URL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
           this.cek_load_data = false;
        });
    },

    addData: function() {
        var formData = new FormData();

        formData.append("judul", this.form.judul);
        formData.append("unit_kerja", this.form.unit_kerja);
        // formData.append("isi", this.form.isi);
        formData.append("isi", CKEDITOR.instances.editor.getData());
        formData.append("file", this.form.file);

        fetch(ADD_URL + "addData", {
            method: "POST",
            headers: {
            authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.alertku("success", "sukses menambah data");
            this.getView();
        });
    },

    editData: function() {
        var formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("judul", this.form.judul);
        formData.append("unit_kerja", this.form.unit_kerja);
        // formData.append("isi", this.form.isi);
        formData.append("isi", CKEDITOR.instances.editor1.getData());
        formData.append("file", this.form.file);
        formData.append("file_old", this.file_old);

        fetch(ADD_URL + "editData", {
            method: "POST",
            headers: {
            authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.alertku("success", "Sukses merubah data");
            this.getView();
        });
    },

    selectData: function(data) {
        console.log(data);
        this.form = {
            id : data.id,
            judul : data.judul,
            isi : data.isi,
            file : data.file,
            file_type : data.file_type,
            unit_kerja : data.unit_kerja,
            status : data.status,
            keterangan : data.keterangan,
            createBy : data.createBy,
            createAt : data.createAt
        };
        this.file_old =  data.file;
        CKEDITOR.instances.editor1.setData(data.isi);
    },


    removeData: function(idnya, file) {
      fetch(ADD_URL + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          id: idnya,
          file: file
        })
      }).then(res_data => {
        this.getView();
        this.alertku("success", "sukses menghapus data");
        this.fileku = null;
      });
    },

    mdl_exit: function() {
        this.form = {
            id : '',
            judul : '',
            isi : '',
            file : '',
            file_type : '',
            unit_kerja : '',
            status : '',
            keterangan : '',
            createBy : '',
            createAt : '',
        };
    },

  // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
            this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
    onFileSelected: function(event) {
      this.form.file = event.target.files[0];
    },
    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      var getBulan = date.getMonth() + 1; var bulan = '';
      if (getBulan == '1') {bulan = 'Jan'} 
      else if(getBulan == '2') {bulan = 'Feb'}
      else if(getBulan == '3') {bulan = 'Mar'}
      else if(getBulan == '4') {bulan = 'Apr'}
      else if(getBulan == '5') {bulan = 'Mei'}
      else if(getBulan == '6') {bulan = 'Jun'}
      else if(getBulan == '7') {bulan = 'Jul'}
      else if(getBulan == '8') {bulan = 'Agt'}
      else if(getBulan == '9') {bulan = 'Sep'}
      else if(getBulan == '10') {bulan = 'Okt'}
      else if(getBulan == '11') {bulan = 'Nov'}
      else if(getBulan == '12') {bulan = 'Des'}



      return date.getDate() + " " + bulan + " " + date.getFullYear();
    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
    },
    cek_gambar : function(data){
        var data1 = data.split('/');
        var data2 = data1[0]
        if (data2 == 'image') {
            return true
        }else{
            return false
        }
    },
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {
    var get_profile = JSON.parse(localStorage.profile);
    // this.unit_kerja_full = get_profile.profile.unit_kerja_nama;
    this.form.unit_kerja = get_profile.profile.unit_kerja;

    this.getView();

    CKEDITOR.replace( 'editor',{
        // width: 793.700787,
        height: 300,
        startupFocus : true,
        tabindex: false,
        extraPlugins: 'tableresize'
    });

            
    CKEDITOR.replace( 'editor1',{
        // width: 793.700787,
        height: 300,
    });









        
    }
};
