
export default {
  data() {
    return {
      leftDrawerOpen: true,

      user: "",
                profile : {
					nama : '',
					nip : '',
					foto : '',
				},
				administrator : false,
				unit_kerja : '',

    };
  },

  methods: {
    logout : function(){
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        this.$router.push('/login');
    },
    get_user : function(){
        fetch(URL_APP, {
            headers: {
                authorization: "kikensbatara " + localStorage.token
        }
        }).then(res => res.json())
        .then(result => {
            if (result.user) {
            this.user = result.user;
            this.profile = result.user.profile;
            localStorage.profile = JSON.stringify(result.user);
            // console.log(localStorage.profile);

            } else {
                this.logout();
            }
        });
    },
  },
  mounted: function(){
      var get_profile = JSON.parse(localStorage.profile);
      this.profile.nama = get_profile.profile.nama;
			this.profile.nip = get_profile.profile.ref_instansi;
			this.unit_kerja = get_profile.profile.unit_kerja;


			var lev_administrator = get_profile.profile.Berita;



			if (lev_administrator == '1') {
				this.administrator = true;
			} else {
				this.administrator = false;
			}


  },
  
};
