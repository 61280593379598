
const ADD_URL = URL_APP + "api/v1/web_berita/";
const ADD_URL_KATEGORI = URL_APP + "api/v1/web_publish_kategori/";
const FILE_LOAD = URL_APP + "uploads/";


export default {
    components: {

  },

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                judul : '',
                sumber : '',
                deskripsi : '',
                isi : '',
                foto : '',
                kategori_id : '',
                unit_kerja : '',
                status : '',
                keterangan : '',
                createBy : '',
                createAt : '',
            },
            kategori_id : '',
            unit_kerja : '',
            unit_kerja_full : '',


            id :'',
            cek_load_data : true,
            file_old: "",
            file_path: FILE_LOAD,

            list_data: [],
            list_kategori: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",

            content: '<p>example content</p>',
            editorOption: { /* quill options */ },

            mdl_add: false,
            mdl_edit: false,
            mdl_hapus : false,
            mdl_lihat : false,
            mdl_lihat_gambar : false,

            btn_add: false,
        }
    },
    methods: {
        onEditorChange(event) {
            console.log('onEditorChange')
        },
        getView: function() {
            this.cek_load_data = true;
            fetch(ADD_URL + "view", {
                method: "POST",
                headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    kategori_id : this.kategori_id,
                    unit_kerja: this.unit_kerja,
                    data_ke: this.page_first,
                    cari_value: this.cari_value
                })
            })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
            this.cek_load_data = false;
            });
        },

        addData: function() {
            var formData = new FormData();

            formData.append("judul", this.form.judul);
            formData.append("sumber", this.form.sumber);
            formData.append("deskripsi", this.form.deskripsi);
            formData.append("kategori_id", this.form.kategori_id);
            formData.append("unit_kerja", this.form.unit_kerja);
            formData.append("isi", this.form.isi);
            formData.append("file", this.form.file);

            fetch(ADD_URL + "addData", {
                method: "POST",
                headers: {
                authorization: "kikensbatara " + localStorage.token
                },
                body: formData
            }).then(res_data => {
              this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
                this.getView();
            });
        },

        editData: function() {
            var formData = new FormData();
            formData.append("id", this.form.id);
            formData.append("judul", this.form.judul);
            formData.append("sumber", this.form.sumber);
            formData.append("deskripsi", this.form.deskripsi);
            formData.append("unit_kerja", this.form.unit_kerja);
            formData.append("isi", this.form.isi);
            formData.append("kategori_id", this.form.kategori_id);
            formData.append("file", this.form.file);
            formData.append("file_old", this.file_old);

            fetch(ADD_URL + "editData", {
                method: "POST",
                headers: {
                authorization: "kikensbatara " + localStorage.token
                },
                body: formData
            }).then(res_data => {
              this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
                this.getView();
            });
        },

        selectData: function(data) {
            console.log(data);
            this.form = {
                id : data.id,
                judul : data.judul,
                sumber : data.sumber,
                deskripsi : data.deskripsi,
                isi : data.isi,
                foto : data.foto,
                kategori_id : data.kategori_id,
                unit_kerja : data.unit_kerja,
                status : data.status,
                keterangan : data.keterangan,
                createBy : data.createBy,
                createAt : data.createAt
            };
            this.file_old =  data.foto;
            // CKEDITOR.instances.editor1.setData(data.isi);
        },


        removeData: function(idnya, file) {
            fetch(ADD_URL + "removeData", {
                method: "POST",
                headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                id: idnya,
                file: file
                })
            }).then(res_data => {
                this.getView();
                this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
                this.fileku = null;
            });
        },

        getKategori: function() {


            fetch(ADD_URL_KATEGORI, {
                method: "GET",
                headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
                }
            })
            .then(res => res.json())
            .then(res_data => {
                this.list_kategori = res_data;
                console.log(this.list_kategori)
            // console.log(res_data)
            });
        },

        mdl_exit: function() {
        this.judul = "";
        this.isi = "";
        this.file = "";
        this.file_old = "";
        },

  // ================== PAGINASI ====================
  Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },

    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
            this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
    
    onFileSelected: function(event) {
      this.form.file = event.target.files[0];
    },
    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      var getBulan = date.getMonth() + 1; var bulan = '';
      if (getBulan == '1') {bulan = 'Jan'} 
      else if(getBulan == '2') {bulan = 'Feb'}
      else if(getBulan == '3') {bulan = 'Mar'}
      else if(getBulan == '4') {bulan = 'Apr'}
      else if(getBulan == '5') {bulan = 'Mei'}
      else if(getBulan == '6') {bulan = 'Jun'}
      else if(getBulan == '7') {bulan = 'Jul'}
      else if(getBulan == '8') {bulan = 'Agt'}
      else if(getBulan == '9') {bulan = 'Sep'}
      else if(getBulan == '10') {bulan = 'Okt'}
      else if(getBulan == '11') {bulan = 'Nov'}
      else if(getBulan == '12') {bulan = 'Des'}

      return date.getDate() + " " + bulan + " " + date.getFullYear();
    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
    }
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {
    var get_profile = JSON.parse(localStorage.profile);
    var status_berita = get_profile.profile.Berita;
    this.form.unit_kerja = get_profile.profile.unit_kerja; 

    this.getKategori();
    
    if (status_berita == 1) {
        this.unit_kerja = '';
    } else {
        this.unit_kerja = get_profile.profile.unit_kerja;
    }



    this.getView();

    // CKEDITOR.replace( 'editor',{
    //     // width: 793.700787,
    //     height: 1122.519685,
    //     startupFocus : true,
    //     tabindex: false,
    //     extraPlugins: 'tableresize'
    // });

            
    // CKEDITOR.replace( 'editor1',{
    //     // width: 793.700787,
    //     height: 1122.519685,
    // });









        
    }
};
